import React from 'react';
import '../Styles/quemsomos.css';

const Quemsomos = () => {
  return (
    <div className='quemsomos-style'>
        <h1 className='main-title'>QUEM SOMOS</h1>
        <p className='main-text'>Somos uma equipa dedicada a transformar a forma como prevenimos incêndios florestais através da tecnologia. Combinamos inteligência artificial avançada e análise de imagens aéreas para criar uma plataforma inovadora que identifica zonas de risco de incêndio antes que o perigo se concretize. Acreditamos que, com a tecnologia certa, é possível proteger as nossas florestas, comunidades e patrimónios de forma proativa e acessível a todos.</p>
    </div>
  );
};

export default Quemsomos;