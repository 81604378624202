import React, { useRef } from 'react';
import Anossaequipa from '../src/Components/Anossaequipa';
import Hero from '../src/Components/Hero';
import Navbar from '../src/Components/Navbar';
import Quemsomos from '../src/Components/Quemsomos';
import Diagnostico from './Components/Diagnostico';
import Funcionalidades from './Components/Funcionalidades'; // Import the Funcionalidades component
import Footer from './Components/Footer'; // Import the Footer component

import './App.css';

function App() {
  // Create refs for each component
  const quemsomosRef = useRef(null);
  const equipaRef = useRef(null);
  const inicioRef = useRef(null);
  const diagnosticoRef = useRef(null);
  const funcionalidadesRef = useRef(null); // Create ref for Funcionalidades

  // Define the scroll offset value (adjust based on navbar height)
  const scrollOffset = -80; // Adjust based on your navbar height

  const scrollToSectionWithOffset = (ref) => {
    const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition + scrollOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  // Create functions to scroll to each ref
  const scrollToInicio = () => scrollToSectionWithOffset(inicioRef);
  const scrollToQuemsomos = () => scrollToSectionWithOffset(quemsomosRef);
  const scrollToEquipa = () => scrollToSectionWithOffset(equipaRef);
  const scrollToDiagnostico = () => diagnosticoRef.current.scrollIntoView({ behavior: 'smooth' });
  const scrollToFuncionalidades = () => scrollToSectionWithOffset(funcionalidadesRef); // Add scroll function for Funcionalidades

  return (
    <div className="App">
      {/* Pass the scrolling functions to the Navbar */}
      <Navbar 
        scrollToInicio={scrollToInicio} 
        scrollToQuemsomos={scrollToQuemsomos} 
        scrollToEquipa={scrollToEquipa} 
        scrollToDiagnostico={scrollToDiagnostico} 
        scrollToFuncionalidades={scrollToFuncionalidades} // Pass the scroll function for Funcionalidades
      />
      
      {/* Add refs to the sections */}
      <div ref={inicioRef}>
        <Hero />
      </div>
      <div ref={quemsomosRef}>
        <Quemsomos />
      </div>
      <div ref={equipaRef}>
        <Anossaequipa />
      </div>
      <div ref={funcionalidadesRef}>
        <Funcionalidades />
      </div>
      <div ref={diagnosticoRef}>
        <Diagnostico />
      </div>
      
      <Footer />  
    </div>
  );
}

export default App;