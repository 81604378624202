import React from 'react';
import '../Styles/diagnostico.css'; // Ensure correct path to CSS

const Diagnostico = () => {
  return (
    <div className="diagnostico-container">
      <h1 className="main-title">DIAGNÓSTICO GRATUÍTO</h1>
      <form className="diagnostico-form"
        action="https://formspree.io/f/mkgnzpye"
          method="POST"
      >
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="nome">Nome</label>
            <input type="text" id="nome" name="nome" />
          </div>
          <div className="form-group">
            <label htmlFor="apelido">Apelido</label>
            <input type="text" id="apelido" name="apelido" />
          </div>
        </div>
        <div className='form-row'>
          <div className="form-group">
           <label htmlFor="email">E-mail:</label>
           <input type="email" id="email" name="email" />
          </div>
          <div className="form-group">
            <label htmlFor="telefone">Número de telefone:</label>
            <input type="text" id="telefone" name="telefone" />
          </div>
        </div>
        <button type="submit" className="submit-button">Submeter</button>
      </form>
    </div>
  );
};

export default Diagnostico;

