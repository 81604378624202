import React, { useState } from 'react';
import '../Styles/NavBar.css'; // Ensure correct CSS path
import skyblazeLogo from '../images/SKYBLAZELOGO.png'; // Import logo

const Navbar = ({ scrollToInicio, scrollToQuemsomos, scrollToEquipa, scrollToDiagnostico, scrollToFuncionalidades }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Function to handle closing the mobile menu after clicking a link
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="navbar">
      {/* Hamburger Menu (visible only in mobile) */}
      <div className="hamburger-menu" onClick={toggleMobileMenu}>
        <span className="hamburger-icon">&#9776;</span> {/* Unicode for the hamburger icon */}
      </div>

      {/* Logo in the center */}
      <div className="logo">
        <img src={skyblazeLogo} alt="Skyblaze Logo" onClick={scrollToInicio} />
      </div>

      {/* CTA Button */}

      {/* Navigation links (hidden in mobile, shown in hamburger dropdown) */}
      <ul className={`nav-links ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <li onClick={() => { scrollToInicio(); closeMobileMenu(); }}>INÍCIO</li>
        <li onClick={() => { scrollToQuemsomos(); closeMobileMenu(); }}>QUEM SOMOS</li>
        <li onClick={() => { scrollToEquipa(); closeMobileMenu(); }}>EQUIPA</li>
        <li onClick={() => { scrollToFuncionalidades(); closeMobileMenu(); }}>FUNCIONALIDADES</li>
      </ul>

      <button className="cta-button" onClick={scrollToDiagnostico}>TESTE GRÁTIS</button>
    </nav>
  );
};

export default Navbar;


