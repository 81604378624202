import React from 'react';
import '../Styles/footer.css'; // Ensure the correct path to the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <img src="path/to/logo.png" alt="Logo" className="footer-logo-image"/>
        </div>
        <div className="footer-links">
          <a href="/about" className="footer-link">About Us</a>
          <a href="/services" className="footer-link">Services</a>
          <a href="/contact" className="footer-link">Contact</a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 SKYBLAZE. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
