import React from 'react';
import '../Styles/hero.css';


const Hero = () => {
  return (
    <div className='hero-style'>
        <h1 className='hero-title'>PREVENÇÃO DE INCÊNCIOS DE FORMA ACESSÍVEL</h1>
        <button className="cta-button">EXPERIMENTE JÁ</button>
    </div>
  );
};

export default Hero;