import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Carousel from '../Components/Caroussel'; // Import the Carousel component
import '../Styles/equipa.css'; // Ensure correct path to CSS

const Anossaequipa = () => {


  return (
    
    <div>
      <div className='equipa-style'>
        <h1 className='main-title'>A NOSSA EQUIPA</h1>
      </div>
      <Carousel /> 
      <div className='equipa-style'>
        <p></p>
        <p></p>
        <p></p>
        <p></p>

      </div>

    </div>
  );
};

export default Anossaequipa;


